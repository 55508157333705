import api from "@/api";
import Button from "@/components/Button";
import Card from "@/components/Card";
import ExportFile from "@/components/ExportFile";
import Pagination from "@/components/Pagination";
import SvgIcon from "@/components/SvgIcon";
import { ButtonListRow, PaginationRow, TableRow } from "@/models/type";
import { debounceClick } from "@/utils";
import { ElNotification } from "element-plus";
import { Vue } from "vue-class-component";
import Doing, { Detailrow } from ".";

export default class DoingDetail extends Vue {

  public doingForm: Detailrow = { total: 0 }

  public formRule: any = {}
  public buttonList: ButtonListRow[] = [
    { title: '批量编辑', click: 'classingEdit', popover: true, popoverList: [
      { title: '添加签到', click: 'signIn' },
      { title: '添加返款金额', click: 'backPrice' },
    ] }
  ]


  public tableData: Array<any> = []
  public tableList: Array<TableRow> = [
    { label: '序号', width: 100, slot: {
      default: (scope: any): JSX.Element => <span>{scope.$index + 1}</span>
    } },
    { label: '真实姓名', width: 150, prop: 'memberName' },
    { label: '用户身份', width: 150, prop: 'levelName' },
    { label: '所属机构', width: 200, prop: 'teamTitle'},
    { label: '身份证号', width: 150, prop: 'cardId' },
    { label: '手机号', width: 150, prop: 'phone' },
    { label: '邮箱', width: 150, prop: 'email' },
    { label: '是否签到', width: 150, slot: {
      default: (scope: any): JSX.Element => <div>{scope.row.chapter === '1' ? '是' : '否'}</div>
    } },
    { label: '支付全额', width: 150, prop: 'payPrice' },
    { label: '返款全额', width: 150, prop: 'returnPrice' },
    { label: '操作', fixed: 'right', slot: {
      default: (scope: any): JSX.Element => <div>
        <span class="span-button-primary" onClick={ () => this.doingDetail(scope.row) }>编辑</span>
      </div>
    } },
  ]

  public exportShow = false

  public fileName = ''
  public exportLoading = false;

  public backPriceBool = false

  public ids: Array<number> = []

  public returnPrice = ''


  goBack(): void {
    const __parent = this.$parent as Doing
    __parent.type = ''
    this.doingForm = { total: 0 }
  }

  doingDetail(row: any): void {
    this.ids = [row.id]
    this.returnPrice = row.returnPrice
    this.backPriceBool = true
  }

  signIn(): void {
    if(!(this.ids.length)) {
      ElNotification({
        type: 'warning',
        message: '请选择要操作的列表'
      })
      return
    }
    const chapterIds = '1'
    api.subjectSubjectSignUpAddSubjectSignUpChapter({chapterIds, returnPrice: this.returnPrice}, this.ids).then(res => {
      ElNotification({
        type: 'success',
        title: '成功',
        message: '操作成功'
      })
      this.getList()
    })
  }

  backPrice(): void {
    if(!(this.ids.length)) {
      ElNotification({
        type: 'warning',
        message: '请选择要操作的列表'
      })
      return
    }
    this.backPriceBool = true
  }


  backPriceDom(): JSX.Element {

    const slots: any = {
      footer: (): JSX.Element => (
        <span class="dialog-footer">
          <span class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.backPriceClose } >取 消</span>
          <span class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.backPriceSubmit) }>确 定</span>
        </span>
      )
    }

    return <el-dialog
      v-model={this.backPriceBool}
      title="添加返款金额"
      before-close={ this.backPriceClose }
      width="30%"
      v-slots={ slots }
    >
      <div style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px'
      }}>
        <span style={{
          width: '120px',
          textAlign: 'right'
        }}>返款金额：</span>
        <el-input v-model={this.returnPrice} placeholder="请输入返款金额" clearable  style="width: 70%"/>
      </div>
    </el-dialog>
  }

  backPriceSubmit(): void {
    api.activeSignUpActivityAddSignUpActivityReturnPrice({returnPrice: this.returnPrice}, this.ids).then(res => {
      ElNotification({
        type: 'success',
        title: '成功',
        message: '操作成功'
      })
      this.backPriceClose()
      this.getList()
    })
  }

  backPriceClose(): void {
    this.returnPrice = ''
    this.backPriceBool = false
  }

  detailDom(): JSX.Element {
    return <div class="doing-detail">
      <el-row gutter={ 50 }>
        <el-col lg={ 8 } md={ 12 } sm={ 24 } class="doing-detail-cell">
          <el-form-item label="活动名称" prop="title">
            <span>{ this.doingForm.title }</span>
          </el-form-item>
        </el-col>
        <el-col lg={ 8 } md={ 12 } sm={ 24 } class="doing-detail-cell">
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_7" />
              <span>活动地点</span>
            </>}}
            prop="position"
          >
            <span>{ this.doingForm.position }</span>
          </el-form-item>
        </el-col>
        <el-col lg={ 8 } md={ 12 } sm={ 24 } class="doing-detail-cell">
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_3" />
              <span>报名时间</span>
            </>}}
            prop="time"
          >
            <span>{ this.doingForm.time }</span>
          </el-form-item>
        </el-col>
        <el-col lg={ 8 } md={ 12 } sm={ 24 } class="doing-detail-cell">
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_5" />
              <span>报名人数</span>
            </>}}
            prop="people"
          >
            <span>{ this.doingForm.people }</span>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="doing-detail-submit" onClick={() => debounceClick(this.export)}>导出</div>
    </div>
  }

  onSelectionChange(row): void {
    this.ids = row.map(item => item.id)
  }

  tableDom(): JSX.Element {
    return (
      <el-table
        ref="table"
        data={ this.doingForm.list }
        onSelectionChange={this.onSelectionChange}
      >
        <el-table-column type="selection" align="center" />
        {this.tableList.map(item => (
          <el-table-column
          prop={ item.prop }
          label={ item.label }
          width={ item.width }
          fixed={ item.fixed }
          align={ item.align || 'center' }
          v-slots={ item.slot }
        />
        ))}
      </el-table>
    )
  }

  export(): void {
    this.fileName = `${this.doingForm.title}-${this.doingForm.position} ${this.doingForm.time}`
    this.exportShow = true
  }

  exportClose(): void {
    this.exportShow = false
  }

  async exportSubmit(data): Promise<any> {
    this.exportLoading = true
    await api.activeSignUpActivityExportSignUpActivity(this.doingForm.id as number, data)
    this.exportLoading = false
  }

  getList(): void {
    const __pagination = this.$refs.pagination as any
    const { pageNum, pageSize } = __pagination.paginationObj as PaginationRow

    api.activeSignUpActivityGetSignUpActivityMemberById({
      pageNum,
      pageSize,
      id: this.doingForm.id as number
    }).then(res => {
      this.doingForm.list = res.data.list
      this.doingForm.total = res.data.total
    })
  }

  buttonClick(click: string): void {
    this[click]()
  }

  classingEdit(): boolean {
    return false
  }

  render(): JSX.Element {
    return <el-form
      ref="form"
      model={ this.doingForm }
      class="doing"
      rules={ this.formRule }
    >
      {this.backPriceBool && this.backPriceDom()}
      <Card title="活动信息" back onGoBack={ this.goBack } class="doing-card">
        {this.detailDom()}
      </Card>
      <Card title="报名信息">
        <div class="doing-search">
          <Button list={ this.buttonList } onClick={this.buttonClick} onPopoverClick={ this.buttonClick }/>
          {this.tableDom()}
          <Pagination ref="pagination" total={ this.doingForm.total } onCurrentChange={ this.getList } onSizeChange={ this.getList } />
        </div>
      </Card>
      { this.exportShow && <ExportFile ref="export_file" loading={ this.exportLoading } isShow={ this.exportShow } onExportClose={ this.exportClose } onExportSubmit={ this.exportSubmit } fileName={ this.fileName } /> }
    </el-form>
  }
}
