import api from "@/api";
import Card from "@/components/Card";
import Pagination from "@/components/Pagination";
import Search from "@/components/Search";
import { SmsActivity } from "@/models/activity";
import { CaphterParams } from "@/models/subject";
import { OptionsRow, PaginationRow, SearchListRow, TableRow } from "@/models/type";
import { parseTime, typeWatch } from "@/utils";
import { Vue, Watch } from "vue-property-decorator";
import { h } from "vue";
import DoingDetail from "./detail";
import './index.scss'

export default class Doing extends Vue {
  public type = ''
  public title = ''
  public total = 0
  public listSum = 0
  public searchObj: any = {}

  public detailRow: Detailrow = { total: 0 }

  public statusList: Array<OptionsRow> = [
    { name: '未开启', value: 0 },
    { name: '报名中', value: 1 },
    { name: '已开始', value: 2 },
    { name: '已结束', value: 3 }
  ]

  private searchList: Array<SearchListRow> = [
    { label: '活动名称', type: 'input', placeholder: '请输入活动名称', submitKey: 'title' },
    { label: '驻场艺术家', icon: 'svg_2', type: 'input', placeholder: '请输入驻场艺术家名称', submitKey: 'articleTitle' },
    { label: '活动状态', type: 'select', options: this.statusList, placeholder: '请输入活动名称', submitKey: 'status' },
    { label: '活动时间', icon: 'svg_3', type: 'time', submitKey: ['activityStartTime', 'activityEndTime'] }
  ]

  public identityList: Array<OptionsRow> = []
  public homedoingList: Array<OptionsRow> = []

  private tableData: Array<any> = []
  public tableList: Array<TableRow> = [
    { label: '序号', slot: {
      default: (scope: any): JSX.Element => <span>{ scope.$index + 1 }</span>
    } },
    { label: '时间', slot: {
      default: (scope: any): JSX.Element => <div>
        <span>{ parseTime(new Date(scope.row.activityTime).getTime(), '{y}-{m}-{d}') }</span>
      </div>
    } },
    { label: '名称', prop: 'title' },
    { label: '驻场艺术家', slot: {
      default: (scope: any): JSX.Element => <span>{ this.articleList.find(item => item.id === scope.row.articleId)?.title }</span>
    } },
    { label: '报名人数', slot: {
      default: (scope: any): JSX.Element => <span>{ `${scope.row.peopleCount}/${scope.row.peopleNumber}` }</span>
    } },
    { label: '状态', slot: {
      default: (scope: any): JSX.Element => <span class={['doing-status', `doing-status-${scope.row.status}`]}>{ this.statusList.find(item => item.value === scope.row.status)?.name }</span>
    } },
    { label: '操作', slot: {
      default: (scope: any): JSX.Element => <div>
        <span class={['span-button-primary', scope.row.loading && 'el-icon-loading']} onClick={ () => this.doingDetail(scope.row, scope.$index) }>{ !scope.row.loading && '详情' }</span>
      </div>
    } },
  ]
  public articleList: any[] = [];

  @Watch('type')
  titleChange(val?: string): void {
    this.title = typeWatch(val)
    switch(val){
      case 'detail':
        this.detailEdit()
        break
      default:
        this.getList()
    }
  }

  detailEdit(): void {
    this.$nextTick(() => {
      const __doingDetail = this.$refs.doingDetail as DoingDetail
      __doingDetail.doingForm = this.detailRow
    })
  }

  async doingDetail(row: SmsActivity, index: number): Promise<void> {
    if(this.tableData[index].loading) {
      return
    }
    this.tableData[index].loading = true

    try {
      const params = { pageNum: 1, pageSize: 10, id: row.id as number } as CaphterParams
      const { data } = await api.activeSignUpActivityGetSignUpActivityMemberById(params)

      const startTime = parseTime(new Date(row.signUpStartTime as Date).getTime(), '{y}-{m}-{d}')
      const endTime = parseTime(new Date(row.signUpEndTime as Date).getTime(), '{y}-{m}-{d}')

      /* 活动名称 活动地点 报名时间 报名人数 */
      const obj: Detailrow = Object.assign({},
        { id: row.id },
        { title: row.title },
        { position: row.position },
        { people: `${row.peopleCount}/${row.peopleNumber}` },
        { time:  `${startTime}-${endTime}`},
        { total: data.total || 0 },
        { list: data.list },
      )

      this.detailRow = obj

      this.type = 'detail'

      this.tableData[index].loading = false
      this.tableData = []
    } catch (error) {
      this.tableData[index].loading = false
    }
  }

  @Watch('listSum')
  listSumChange(sum: number): void {
    if(sum) {
      this.getList()
    }
  }

  buttonClick(click: string): void {
    this[click]()
  }

  onSearch(row: never): void {
    this.searchObj = Object.assign({}, row)
    this.getList()
  }

  tableDom(): JSX.Element {
    return (
      <el-table
        ref="table"
        data={ this.tableData }
      >
        {this.tableList.map(item => (
          <el-table-column
          prop={ item.prop }
          label={ item.label }
          align={ item.align || 'center' }
          v-slots={ item.slot }
        />
        ))}
      </el-table>
    )
  }

  getList(): void {
    this.$nextTick(() => {
      const __pagination = this.$refs.pagination as any
      const { pageNum, pageSize } = __pagination.paginationObj as PaginationRow
      api.activeActivityGetActivityList({ pageNum, pageSize, ...this.searchObj }).then(res => {
        this.tableData = res.data.list.map(item => ({ ...item, loading: false }))
        this.total = res.data.total
      })
    })
  }

  getData(): void {
    Promise.all([
      api.activeArticleGetArticleListSelect().then(res => {
        this.articleList = res.data
      })
    ])
  }

  created(): void {
    this.getData()
  }

  render(): JSX.Element {
    return this.type
      ? h(DoingDetail, { ref: 'doingDetail' })
      : <div class="doing">
        <Card title="活动查询" class="doing-card">
          <Search list={ this.searchList } class="doing-search" onSearch={ this.onSearch } />
        </Card>
        <Card title="活动管理">
          <div class="doing-search">
            {this.tableDom()}
            <Pagination ref="pagination" total={ this.total } onCurrentChange={ this.getList } onSizeChange={ this.getList } />
          </div>
        </Card>
      </div>
  }

  mounted(): void {
    this.getList()
  }
}

export interface Detailrow {
  id?: number
  total: number
  title?: string
  time?: string
  position?: string
  people?: string
  list?: Array<any>
}
