import { debounceClick } from "@/utils";
import { defineComponent, reactive, toRefs } from "vue";
import './index.scss'

/**
 *  导出文件
 *  @type {*} */
const ExportFile = defineComponent({
  props: {
    isShow: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
      required: true
    },
    fileName: {
      type: String,
      default: ''
    },
    onExportClose: {
      type: Function,
      required: true
    },
    onExportSubmit: {
      type: Function,
      required: true
    }
  },
  emits: ['exportClose', 'exportSubmit'],
  setup(props, { emit }) {
    const { isShow, fileName, loading } = toRefs(props)

    const exportForm = reactive({
      fileName: fileName.value,
      fileExtension: 'xls'
    })

    const typeList = [
      { title: 'Excel 97-2003 工作簿(*.xls)', fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8', fileExtension: 'xls' },
      { title: 'Excel 工作簿(*.xlsx)', fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8', fileExtension: 'xlsx' }
    ]

    const formRules = {}

    function exportClose() {
      emit('exportClose')
    }

    function submit() {
      const obj = Object.assign({}, exportForm) as any
      obj.fileType = typeList.find(item => item.fileExtension === obj.fileExtension)?.fileType
      emit('exportSubmit', exportForm)
    }

    const slots = {
      footer: (): JSX.Element => (
        <span class="dialog-footer">
          <span class="dialog-footer-btn dialog-footer-btn-l" onClick={ exportClose } >取 消</span>
          {loading.value
            ? <span  class="dialog-footer-btn dialog-footer-btn-r">
                <i class="el-icon-loading" />
              </span>
            : <span class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(submit) }>确 认 导 出</span>
          }
        </span>
      )
    }
    return () => (
      <el-dialog
        title="导出表格"
        v-model={ isShow.value }
        before-close={ exportClose }
        width="30%"
        v-slots={ slots }
      >
        <el-form
          ref="form"
          model={ exportForm }
          rules={ formRules }
          label-width="120px"
          class="el-form-tao"
        >
          <el-form-item label="文件名" prop="fileName">
            <el-input v-model={ exportForm.fileName } clearable placeholder="请输入导出文件名" />
          </el-form-item>
          <el-form-item label="导出格式" prop="fileExtension">
            <el-select v-model={ exportForm.fileExtension } clearable placeholder="请选择导出格式" style="width:100%">
              {typeList.map(item => {
                return <el-option label={ item.title } value={ item.fileExtension } />
              })}
            </el-select>
          </el-form-item>
        </el-form>
      </el-dialog>
    )
  }
})

export default ExportFile
